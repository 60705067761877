import { Container, Grid, Typography } from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import { makeStyles } from "@material-ui/styles";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import Button from "../components/Button";
import CheckList from "../components/CheckList";
import InlineNumberPoint from "../components/InlineNumberPoint";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";
import StackedPoint from "../components/StackedPoint";

const useStyles = makeStyles(theme => ({
  heroContainer: {
    backgroundColor: theme.palette.common.dark,
    color: theme.palette.common.white
  },
  heroTitle: {
    marginBottom: theme.spacing(2)
  },
  heroButton: {
    marginTop: theme.spacing(3)
  },
  servicesSubtitle: {
    marginBottom: theme.spacing(4)
  },
  otherServicesContainer: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const PropertyFinancePage = () => {
  const classes = useStyles();
  const title = "Property Finance";
  const description =
    "Commercial Finance Group are specialists in sourcing funding for medium and large scale commercial property purchases and/or development projects in the UK and abroad.";

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.heroContainer}>
        <Container>
          <Typography className={classes.heroTitle} variant="h1">
            {title}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {description}
          </Typography>
          <CheckList
            points={[
              "Get fast results",
              "Get the best best rate",
              "Expert advice from just £399"
            ]}
          />
          <Button
            className={classes.heroButton}
            variant="contained"
            color="primary"
            giant
            component={GatsbyLink}
            to="/commercial-mortgage"
          >
            Get Quote
          </Button>
        </Container>
      </Section>
      <Section>
        <Container>
          <Typography
            className={classes.servicesSubtitle}
            variant="h6"
            component="h1"
          >
            Services
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <StackedPoint
                title="Commercial Mortgage"
                description="Competitive commercial mortgages with rates generally starting from 2.25% over Bank of England base."
                link="/commercial-mortgage/"
                linkText="Get Quote"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StackedPoint
                title="Bridging Loan"
                description="1st or 2nd charge short term funding for a new purchase, capital raise against commercial, semi-commercial or residential property."
                link="/bridging-loan/"
                linkText="Get Quote"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StackedPoint
                title="Property Development"
                description="Low cost development funding for experienced developers, and those starting the journey."
                link="/property-development/"
                linkText="Get Quote"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StackedPoint
                title="Buy-to-let"
                description="Whether your an individual or limited company, we can help find funding for your next step."
                link="/buy-to-let/"
                linkText="Get Quote"
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section className={classes.otherServicesContainer}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <InlineNumberPoint
                Icon={GroupOutlinedIcon}
                figure="50+"
                title="Expert"
                description="advisers ready to help find funding."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InlineNumberPoint
                Icon={LocalOfferOutlinedIcon}
                figure="£35b+"
                title="In projects"
                description="handled by our team."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InlineNumberPoint
                Icon={EventAvailableIcon}
                figure="10"
                title="Years"
                description="established commercial finance specialist."
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default PropertyFinancePage;
